import '@fontsource/source-sans-pro/400.css';
import '@fontsource/source-sans-pro/600.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import React, { Suspense } from 'react';
import { Redirect } from 'react-router';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { PageNotFound } from './404';
import { secureAxiosInstance } from './api-service';
import { CitizenClient } from './api-service/clients';
import { moduleClientFactory } from './api-service/module-client-factory';
import { adminAreaPathName } from './areas/admin/admin-area-pathnames';
import { DownloadAuditlogReport } from './areas/admin/auditlog/download-auditlog-report';
import { externalOperatorAgreementsEditPathName, externalOperatorPathName } from './areas/admin/organization/organization-pathnames';
import { embeddedPathName, homeAreaPathName, standAlonePathName } from './areas/area-pathnames';
import AssessmentRerouter from './areas/citizen/availability/assessment/rerouter';
import NegativeEventRerouter from './areas/citizen/availability/negative-events/rerouter';
import {
  citizenAreaPathName,
  citizenCasesOverviewAreaPathName,
  citizenCreateReportGuide,
  citizenCrowdBookingPath,
  citizenCrowdLetterPathName,
  citizenInterviewPath,
  citizenJournalNote,
  citizenParameter,
  citizenRoutingAbsencePathName,
  citizenRoutingAssessmentPathName,
  citizenRoutingNegativeEventPathName,
  citizensOverviewAreaPathName,
  citizenTasksOverviewAreaPathName,
  interviewTabPathName,
} from './areas/citizen/citizen-area-pathnames';
import { dashboardPathName } from './areas/dashboard/dashboard-area-pathnames';
import { effortsAreaPathName } from './areas/efforts/efforts-area-pathnames';
import { IncomingMailFileViewer } from './areas/incoming-mail/incoming-mail-file-viewer';
import { incomingMailFilePathname, incomingMailPathname } from './areas/incoming-mail/pathnames';
import { generalTaskPathname } from './areas/jobcenter-task/pathnames';
import { offerAndActivityAreaPathName } from './areas/offer-and-activity/offer-and-activity-area-pathnames';
import { outboxAreaPathName } from './areas/outbox/outbox-area-pathnames';
import { SearchFieldProvider } from './areas/search/hooks/useSearchFields';
import { searchAreaPathName } from './areas/search/search-area-pathnames';
import { AllowedActionsProvider, FkoErrorPage } from './authentication';
import { initializeAuthentication } from './authentication/auth-initialize';
import { AuthWrapper } from './authentication/auth-wrapper';
import { CircularProgressCentered } from './components/circular-progress-centered/circular-progress-centered';
import { DeeplinkRouter } from './components/deeplink-router/deeplink-router';
import EducationEnvironmentTerms from './components/education-environment-terms/education-environment-terms';
import { ErrorBoundary } from './components/error-boundary/error-boundary';
import { NotifierDisplay, NotifierProvider } from './components/notifier';
import { Omnibar } from './components/omnibar/omnibar';
import { OmnibarProvider } from './components/omnibar/omnibar-provider';
import { TenantAddonsProvider } from './components/tenant-addons';
import { ResourceRedirect, WithCrmHostUrlContext } from './components/resource-redirect';
import { configuration } from './configuration';
import { WithFeatureTogglesFromApi } from './feature-toggles';
import { WithBroadcastContext } from './hooks';
import { TenantConfigurationProvider } from './hooks/use-tenant-configuration';
import { initializeLocale } from './locale';
import { initializeTenantSupport } from './tenant-support';
import { ErrorMessageInterceptor } from './utilities/error-message-interceptor';
import { joinURL } from './utilities/join-url';

LicenseInfo.setLicenseKey('344fdd74f7af01705046588141d0ceebT1JERVI6MzQ0MDUsRVhQSVJZPTE2NzExOTE3ODMwMDAsS0VZVkVSU0lPTj0x');

const showNonReadyComponents = configuration.showNonProductionReadyComponents;
initializeLocale();

const tenant = initializeTenantSupport();

const userManager = initializeAuthentication({
  clientId: `jobcenter-desktop-frontend-${tenant.name}`,
  apiName: 'jobcenter-desktop-bff',
});

function renderError() {
  return (
    <>
      <h1>Fejl ved login</h1>
      <p>Der er desværre gået noget galt ved login. Hvis du bliver ved med at få denne fejl, bør du kontakte Schultz kundesupport</p>
    </>
  );
}

const featureToggleClient = moduleClientFactory.createFeatureToggleClient();
const configurationClient = moduleClientFactory.createConfigurationClient();
const AdminArea = React.lazy(() => import(/* webpackChunkName: "admin" */ './areas/admin'));
const CitizensOverviewArea = React.lazy(() => import(/* webpackChunkName: "citizens-overview" */ './areas/citizen/overview/citizens-overview-area'));
const CitizenArea = React.lazy(() => import(/* webpackChunkName: "citizen" */ './areas/citizen'));
const CitizenAbsenceReRouter = React.lazy(() => import(/* webpackChunkName: "citizen" */ './areas/citizen/availability/absence/rerouter'));
const CitizenDashboard = React.lazy(() => import(/* webpackChunkName: "citizen-dashboard" */ './areas/citizen/components/citizen-dashboard'));
const DashboardStartpage = React.lazy(() => import(/* webpackChunkName: "citizen-dashboard" */ './areas/dashboard/dashboard-startpage'));
const DashboardStartpageFullpage = React.lazy(() => import(/* webpackChunkName: "citizen-dashboard" */ './areas/dashboard/dashboard-startpage-fullscreen'));
const CrowdBooking = React.lazy(() => import(/* webpackChunkName: "citizen-crowd-booking" */ './areas/citizen/interview/crowd-booking/crowd-booking'));
const CitizenCrowdLetterPathName = React.lazy(() => import(/* webpackChunkName: "send-crowd-letter-guide" */ './areas/citizen/communication/components/send-crowd-letter-guide'));
const CitizenInterviewReRouter = React.lazy(() => import(/* webpackChunkName: "citizen" */ './areas/citizen/interview'));
const CitizenInterviewBookingReRouter = React.lazy(() => import(/* webpackChunkName: "citizen" */ './areas/citizen/interview/interview-booking-rerouter-index'));
const CitizenJobsearchReRouter = React.lazy(() => import(/* webpackChunkName: "citizen" */ './areas/citizen/cv-jobsearch'));
const CitizenCaseReRouter = React.lazy(() => import(/* webpackChunkName: "citizen" */ './areas/citizen/case'));
const CitizenStartPage = React.lazy(() => import(/* webpackChunkName: "citizen" */ './areas/citizen/overview/start-pages/citizen-start-page'));
const CitizenCaseStartPage = React.lazy(() => import(/* webpackChunkName: "citizen" */ './areas/citizen/overview/start-pages/citizen-case-start-page'));
const CitizenTaskStartPage = React.lazy(() => import(/* webpackChunkName: "citizen" */ './areas/citizen/overview/start-pages/citizen-task-start-page'));
const EmbeddedStartPage = React.lazy(() => import(/* webpackChunkName: "citizen" */ './areas/citizen/overview/start-pages'));
const CitizenDocumentRerouting = React.lazy(() => import(/* webpackChunkName: "citizen" */ './areas/citizen/journal/document/routing'));
const CitizenContractRerouting = React.lazy(() => import(/* webpackChunkName: "citizen" */ './areas/citizen/contract/routing'));
const CitizenPlanRerouting = React.lazy(() => import(/* webpackChunkName: "citizen" */ './areas/citizen/plan/routing'));
const CitizenJournalNoteRerouting = React.lazy(() => import(/* webpackChunkName: "citizen" */ './areas/citizen/journal/journal-note/routing'));
const CitizenContactGroupReRouter = React.lazy(() => import(/* webpackChunkName: "citizen" */ './areas/citizen/contact-groups'));
const CitizenReport = React.lazy(() => import(/* webpackChunkName: "citizen-report" */ './areas/citizen/citizen-report/lazy'));

const CompanyRoutes = React.lazy(() => import(/* webpackChunkName: "company-routes" */ './areas/company/_routes/company-routes'));
const EmbeddedCompanyRoutes = React.lazy(() => import(/* webpackChunkName: "embedded-company-routes" */ './areas/company/_routes/embedded-company-routes'));
const StandaloneCompanyRoutes = React.lazy(() => import(/* webpackChunkName: "standalone-company-routes" */ './areas/company/_routes/standalone-company-routes'));

const EffortsArea = React.lazy(() => import(/* webpackChunkName: "efforts" */ './areas/efforts'));
const OfferAndActivityArea = React.lazy(() => import(/* webpackChunkName: "offer-and-activity" */ './areas/offer-and-activity'));
const OutboxArea = React.lazy(() => import(/* webpackChunkName: "outbox" */ './areas/outbox'));
const SearchArea = React.lazy(() => import(/* webpackChunkName: "search" */ './areas/search'));
const IncomingMail = React.lazy(() => import(/* webpackChunkName: "incoming-mail" */ './areas/incoming-mail/incoming-mail'));
const GeneralTask = React.lazy(() => import(/* webpackChunkName: "general-task" */ './areas/jobcenter-task/general-task/lazy'));
const ExternalOperatorAgreementUniformContainer = React.lazy(
  () =>
    import(
      /* webpackChunkName: "externalOperator" */ './areas/admin/organization/external-operator/external-operator-agreement-uniform/external-operator-agreement-uniform-container'
    )
);

export function App() {
  return (
    <ErrorBoundary>
      <BrowserRouter>
        <AuthWrapper renderError={renderError} renderFkoErrorPage={FkoErrorPage} userManager={userManager}>
          <AllowedActionsProvider>
            <WithBroadcastContext>
              <WithCrmHostUrlContext configurationClient={moduleClientFactory.createConfigurationClient()}>
                <WithFeatureTogglesFromApi client={featureToggleClient}>
                  <NotifierProvider>
                    <ErrorMessageInterceptor />
                    <TenantConfigurationProvider>
                      <SearchFieldProvider>
                        <OmnibarProvider>
                          <Suspense fallback={<CircularProgressCentered />}>
                            <Route exact path="/redirectto">
                              <Redirect to="/" />
                            </Route>
                            <Route exact path="/search/fasitloginflow">
                              <Redirect to="/" />
                            </Route>
                            <Route exact path="/fasitloginflow">
                              <Redirect to="/" />
                            </Route>
                            <Route path="/deeplink">
                              <DeeplinkRouter />
                            </Route>
                            <Switch>
                              <Route path={homeAreaPathName} exact>
                                <DashboardStartpage />
                              </Route>
                              <Route path={`${homeAreaPathName}${dashboardPathName}/:id`} exact>
                                <DashboardStartpageFullpage />
                              </Route>
                              <Route path={joinURL('/embedded/', dashboardPathName)} exact>
                                <DashboardStartpage embedded />
                              </Route>
                              <Route path={joinURL('/', citizensOverviewAreaPathName, interviewTabPathName, citizenCrowdBookingPath)} exact>
                                <TenantAddonsProvider>
                                  <CrowdBooking />
                                </TenantAddonsProvider>
                              </Route>
                              <Route path={`/${citizensOverviewAreaPathName}/${citizenCreateReportGuide}`} exact>
                                <CitizenReport />
                              </Route>
                              <Route path={joinURL('/embedded/', citizenCasesOverviewAreaPathName)}>
                                <EmbeddedStartPage startPage={<CitizenCaseStartPage embedded />} resultTypeDisplayName="Sager" />
                              </Route>
                              <Route path={joinURL('/embedded/', citizenTasksOverviewAreaPathName)}>
                                <EmbeddedStartPage startPage={<CitizenTaskStartPage embedded />} resultTypeDisplayName="Opgaver" />
                              </Route>
                              <Route path={joinURL('/embedded/', citizensOverviewAreaPathName)}>
                                <EmbeddedStartPage startPage={<CitizenStartPage embedded />} resultTypeDisplayName="Borgere" />
                              </Route>
                              <Route path={joinURL('/', citizenCrowdLetterPathName)}>
                                <TenantAddonsProvider>
                                  <CitizenCrowdLetterPathName />
                                </TenantAddonsProvider>
                              </Route>
                              <Route path={joinURL('/', citizensOverviewAreaPathName)}>
                                <CitizensOverviewArea />
                              </Route>
                              <Route path={joinURL('/', citizenAreaPathName, citizenParameter)}>
                                <CitizenArea layout="default" />
                              </Route>
                              <Route path={joinURL('/', embeddedPathName, citizenAreaPathName, citizenParameter)}>
                                <CitizenArea layout="embedded" />
                              </Route>
                              <Route path={joinURL('/', standAlonePathName, citizenAreaPathName, citizenParameter)}>
                                <CitizenArea layout="standalone" />
                              </Route>
                              <Route path={joinURL('/', citizenRoutingAbsencePathName)}>
                                <CitizenAbsenceReRouter />
                              </Route>
                              <Route path={joinURL('/', citizenRoutingNegativeEventPathName)}>
                                <NegativeEventRerouter />
                              </Route>
                              <Route path={joinURL('/', citizenRoutingAssessmentPathName)}>
                                <AssessmentRerouter />
                              </Route>
                              <Route path={joinURL('/', citizenInterviewPath, ':interviewId')}>
                                <CitizenInterviewReRouter />
                              </Route>
                              <Route path={joinURL('/', 'citizeninterviewbooking', ':crmHost', ':bookingId')}>
                                <CitizenInterviewBookingReRouter />
                              </Route>
                              <Route path={joinURL('/', 'citizencontactgroup', ':contactGroupId')}>
                                <CitizenContactGroupReRouter />
                              </Route>
                              <Route path={joinURL('/', 'jobsearch', ':areaName', ':areaId')}>
                                <CitizenJobsearchReRouter />
                              </Route>
                              <Route path={joinURL('/', 'case', ':areaName', ':areaId')}>
                                <CitizenCaseReRouter />
                              </Route>
                              <Route path={joinURL('/', 'citizendocument', ':documentId')}>
                                <CitizenDocumentRerouting />
                              </Route>
                              <Route path={joinURL('/', 'citizencontract', ':contractId')}>
                                <CitizenContractRerouting />
                              </Route>
                              <Route path={joinURL('/', 'citizenplan', ':planId')}>
                                <CitizenPlanRerouting />
                              </Route>
                              <Route path={joinURL('/', citizenJournalNote, ':journalNoteId')}>
                                <CitizenJournalNoteRerouting />
                              </Route>
                              {/* Company routes */}
                              <Route path="/company" component={CompanyRoutes} />
                              <Route path="/embedded/company" component={EmbeddedCompanyRoutes} />
                              <Route path="/standalone/company" component={StandaloneCompanyRoutes} />
                              {/* ---- */}
                              <Route path={`/${outboxAreaPathName}`}>
                                <OutboxArea embedded={false} />
                              </Route>
                              <Route path={`/embedded/${outboxAreaPathName}`}>
                                <OutboxArea embedded />
                              </Route>
                              <Route path={joinURL('/', searchAreaPathName)}>
                                <SearchArea embedded={false} />
                              </Route>
                              <Route path={joinURL('/', embeddedPathName, searchAreaPathName)}>
                                <SearchArea embedded />
                              </Route>
                              <Route path={joinURL('/', effortsAreaPathName)}>
                                <TenantAddonsProvider>
                                  <EffortsArea embedded={false} />
                                </TenantAddonsProvider>
                              </Route>
                              <Route path={`/embedded/${effortsAreaPathName}`}>
                                <TenantAddonsProvider>
                                  <EffortsArea embedded />
                                </TenantAddonsProvider>
                              </Route>
                              <Route path={joinURL('/', offerAndActivityAreaPathName)}>
                                <TenantAddonsProvider>
                                  <OfferAndActivityArea />
                                </TenantAddonsProvider>
                              </Route>
                              <Route exact path="/auditlog/download/:reportId">
                                <DownloadAuditlogReport />
                              </Route>
                              <Route path={joinURL('/', embeddedPathName, adminAreaPathName)}>
                                <TenantAddonsProvider>
                                  <AdminArea embedded />
                                </TenantAddonsProvider>
                              </Route>
                              <Route path={joinURL('/', standAlonePathName, adminAreaPathName)}>
                                <TenantAddonsProvider>
                                  <AdminArea standAlone />
                                </TenantAddonsProvider>
                              </Route>
                              <Route path={joinURL('/', adminAreaPathName)}>
                                <TenantAddonsProvider>
                                  <AdminArea />
                                </TenantAddonsProvider>
                              </Route>
                              <Route path={`/${incomingMailPathname}/:id`}>
                                <IncomingMail />
                              </Route>
                              <Route path={`/${incomingMailFilePathname}/:fileId`}>
                                <IncomingMailFileViewer />
                              </Route>
                              <Route path={`/${generalTaskPathname}/:taskId`}>
                                <GeneralTask />
                              </Route>
                              <Route path="/embedded/*">
                                <PageNotFound standAlone />
                              </Route>
                              <Route path="/gotoresource/:resourceName">
                                <WithCrmHostUrlContext configurationClient={configurationClient}>
                                  <ResourceRedirect />
                                </WithCrmHostUrlContext>
                              </Route>
                              {showNonReadyComponents && (
                                <Route path="/__developer" exact>
                                  <CitizenDashboard citizenClient={new CitizenClient(configuration.bffBase, secureAxiosInstance)} />
                                </Route>
                              )}

                              <Route exact path={joinURL('/', externalOperatorPathName, externalOperatorAgreementsEditPathName)}>
                                <TenantAddonsProvider>
                                  <ExternalOperatorAgreementUniformContainer />
                                </TenantAddonsProvider>
                              </Route>
                              <Route exact path={joinURL('/', externalOperatorPathName)}>
                                <TenantAddonsProvider>
                                  <ExternalOperatorAgreementUniformContainer />
                                </TenantAddonsProvider>
                              </Route>
                              <Route path="*">
                                <PageNotFound />
                              </Route>
                            </Switch>
                          </Suspense>
                          <TenantAddonsProvider>
                            <Omnibar />
                          </TenantAddonsProvider>
                          <EducationEnvironmentTerms />
                          <NotifierDisplay />
                        </OmnibarProvider>
                      </SearchFieldProvider>
                    </TenantConfigurationProvider>
                  </NotifierProvider>
                </WithFeatureTogglesFromApi>
              </WithCrmHostUrlContext>
            </WithBroadcastContext>
          </AllowedActionsProvider>
        </AuthWrapper>
      </BrowserRouter>
    </ErrorBoundary>
  );
}
