export const companyAreaMenuName = 'Arbejdsområde Virksomhed';
export const companyAreaSubMenuName = 'Virksomheder';
export const companyAreaPathName = 'company';
export const companyHistoryTabLabel = 'Historik';
export const companyHistoryNewTabLabel = 'Ny Historik';
export const companyHistoryPathName = 'history';
export const companyHistoryNewPathName = 'history-new';
export const companyOffersTabLabel = 'Job & Tilbud';
export const companyOffersPathName = 'offers';
export const companyActivitiesTabLabel = 'Aktiviteter';
export const companyActivitiesPathName = 'activities';
export const companyVisitPreparationPathName = 'companyvisitpreparation';
export const companyTasksOverviewAreaPathName = 'tasks';
export const companyAppoinmentsOverviewAreaPathName = 'appointments';
export const companyTasksCreatePathName = 'tasks/create';
export const companyTasksEditPathName = 'tasks/edit';
export const companyTasksEditParameter = ':taskId';
export const companyOverviewTabLabel = 'Virksomhedsoverblik';
export const companyOverviewPathName = 'details';
export const companyLabourNeedsLabel = 'Arbejdskraftsbehov';
export const companyLabourNeedsPathName = 'labourneeds';
export const companyLabourNeedsEditPathName = 'labourneeds/:labourdeedsId';
export const companyParameter = ':companyId';

export const companyCreateLetter = 'create-letter';
export const companyGuideForm = 'guide-form';
export const companyCrowdLetterPathName = 'crowd-letter';
export const companyAssociatedCaseWorkerPathName = 'edit-associated-caseworker';
export const companyAssociatedCaseWorkerEditPathName = 'edit-associated-caseworker/:associatedCaseWorkerId?';
export const editTradeCodesPathName = 'edit-trade-codes/:tradeCode?';
export const companyTagsPathName = 'edit-tags';
export const editTagsPathName = 'edit-tags/:id?';

export const companyContactPersonTabLabel = 'Virksomhedskontakt';
export const companyRelationsPathName = 'relations';
export const companyContactPersonPathName = 'edit-company-contact-person';
export const companyContactPersonEditPathName = 'edit-company-contact-person/:contactPersonId?';

export const companyNoteCreatePathName = 'create-company-note';
export const companyNotePathName = 'edit-company-note';
export const companyNoteEditPathName = 'edit-company-note/:noteId?';

export const companyRoutingPathName = 'routing';
export const companyRoutingLabourNeedPathName = 'labourneed/:id';
export const companyRoutingAssociatedCaseWorkerPathName = 'associated-caseworker/:id';
export const companyRoutingSpecialCircumstancePathName = 'special-circumstance/:id';
export const companyRoutingTagPathName = 'tag/:id';
export const companyRoutingCompanyNotePathName = 'company-note/:id';
export const companyRoutingAppointmentPathName = 'appointment/:id';
export const companyRoutingContactPersonPathName = 'contact-person/:id';

export const companySpecialCircumstancePathName = 'edit-company-special-circumstance';
export const companySpecialCircumstanceEditPathName = 'edit-company-special-circumstance/:specialCircumstanceId?';

export const companyAbsencesPathName = 'absences';

export const companyAppointmentCreatePathName = 'create-appointment';
export const companyAppointmentViewPathName = 'view-appointment';
export const companyAppointmentViewEditPathName = 'view-appointment/:appointmentId?';

export const companyFvdbPathName = 'fvdb';
