import React, { useEffect, useState, PropsWithChildren, useContext } from 'react';
import { postBroadcastChannel, subscribeBroadcastChannel, unsubscribeBroadcastChannel } from './broadcast-utils';

export type MessageTopic =
  | 'InterviewConducted'
  | 'InterviewBooked'
  | 'journal'
  | 'plan'
  | 'CandidateReferred'
  | 'InterviewBookingDeadlineCreated'
  | 'InterviewPersonCategoryCreated'
  | 'JobAds'
  | 'Other'
  | 'Activity'
  | 'ActivityApproval'
  | 'OfferPriceGuide'
  | 'CaseCaseWorkerUpdate'
  | 'ContactGroupUpdate'
  | 'GlobalToasterSuccess'
  | 'GlobalToasterInfo'
  | 'HealthLimitations'
  | 'ActivityEvaluation'
  | 'EnrollmentUpdate'
  | 'ReloadCitizenHeader'
  | 'NegativeEventUpdate'
  | 'CitizenCoreUpdate'
  | 'CompanyMarkingsUpdate'
  | 'CompanyTradeCodesUpdate'
  | 'CompanyTagsUpdate'
  | 'AssessmentUpdate'
  | 'CompanyTask'
  | 'CitizenTask'
  | 'AbsenceUpdate'
  | 'CompanyContactPersonUpdate'
  | 'PrivateContactPersonUpdate'
  | 'SendLetterData'
  | 'CitizenPersonContactUpdate'
  | 'CompanySpecialCircumstanceUpdate'
  | 'CompanyLabourNeedsUpdate'
  | 'CompanyNoteUpdate'
  | 'CompanyAppointmentUpdate'
  | 'ReloadCitizenDetailsTabMenuSummary'
  | 'UserCreatedOrUpdated'
  | 'JobReferral'
  | 'NuphUpdate'
  | 'CompanyRecruitment';

export interface IBroadcastChannel {
  /**
   * The channel name
   */
  name: string;
  /**
   * Closes the BroadcastChannel object, opening it up to garbage collection.
   */
  close: () => void;
  dispatchEvent: (event: Event) => boolean;
  addEventListener: (type: 'message' | 'messageerror', listener: (this: BroadcastChannel, ev: MessageEvent) => any) => void;
  onmessage: ((this: BroadcastChannel, ev: MessageEvent) => any) | null;
  onmessageerror: ((this: BroadcastChannel, ev: MessageEvent) => any) | null;
  postMessage: (message: ICommunicationMessage) => void;
  removeEventListener: (
    type: 'message' | 'messageerror',
    listener: (this: BroadcastChannel, ev: MessageEvent) => any,
    options?: boolean | EventListenerOptions | undefined
  ) => void;
}

export interface ICommunicationMessage {
  topic: MessageTopic;
  data: string;
}

export interface IBroadcastContextWrapper {
  subscribe: (id: string, callback: (message: ICommunicationMessage) => void, topic: MessageTopic) => void;
  post: (message: ICommunicationMessage) => void;
  unsubscribe: (id: string, topic: MessageTopic) => void;
  setup: () => void;
}

export interface IBroadcastContext {
  subscribe: (callback: (message: ICommunicationMessage) => void) => void;
  send: (message?: string) => void;
}

let globalBroadcastChannel: IBroadcastChannel | undefined;
function setupBroadcastChannel(subscriptions: { [topic: string]: { id: string; callback: (message: ICommunicationMessage) => void }[] | undefined }) {
  if (globalBroadcastChannel !== undefined) {
    return;
  }
  // Check if BroadcastChannel feature is enabled
  if (!window.BroadcastChannel) {
    return;
  }
  globalBroadcastChannel = new BroadcastChannel('Fasit');
  globalBroadcastChannel.onmessage = (ev: MessageEvent) => {
    const { data, topic } = ev.data as ICommunicationMessage;
    const broadcastChannelHandlers = subscriptions[topic];
    if (broadcastChannelHandlers === undefined || broadcastChannelHandlers.length === 0) {
      return;
    }
    broadcastChannelHandlers.forEach(handler =>
      setTimeout(() => {
        handler.callback({ data, topic });
      }, 100)
    );
  };
}

const BroadcastContext = React.createContext<IBroadcastContextWrapper>({} as IBroadcastContextWrapper);

export function WithTestBroadcastContext(props: PropsWithChildren<{ broadcast?: IBroadcastChannel }>) {
  const {
    children,
    broadcast = {
      postMessage: () => ({}),
      close: () => ({}),
      name: '',
      onmessage: () => ({}),
      addEventListener: () => ({}),
      dispatchEvent: () => false,
      onmessageerror: () => ({}),
      removeEventListener: () => ({}),
    },
  } = props;
  const dummyfunction = () => ({});
  const subscribers: { [topic: string]: { id: string; callback: (message: ICommunicationMessage) => void }[] | undefined } = {};

  return (
    <BroadcastContext.Provider
      value={{
        post: dummyfunction,
        setup: dummyfunction,
        subscribe: dummyfunction,
        unsubscribe: dummyfunction,
      }}
    >
      {children}
    </BroadcastContext.Provider>
  );
}

export function WithBroadcastContext(props: PropsWithChildren<unknown>) {
  const { children } = props;
  const [subscriptions] = useState({} as { [topic: string]: { id: string; callback: (message: ICommunicationMessage) => void }[] });
  useEffect(
    () => () => {
      if (globalBroadcastChannel) {
        globalBroadcastChannel.close();
        globalBroadcastChannel = undefined;
      }
    },
    []
  );
  return (
    <BroadcastContext.Provider
      value={{
        post: (message: ICommunicationMessage) => {
          postBroadcastChannel(message, subscriptions || {}, globalBroadcastChannel);
        },
        subscribe: (id: string, callback: (message: ICommunicationMessage) => void, topic: MessageTopic) => {
          subscriptions[topic] = subscriptions[topic] || [];
          subscribeBroadcastChannel(id, callback, subscriptions[topic] || []);
        },
        unsubscribe: (id: string, topic: MessageTopic) => unsubscribeBroadcastChannel(id, topic, subscriptions || {}),
        setup: () => {
          setupBroadcastChannel(subscriptions);
        },
      }}
    >
      {children}
    </BroadcastContext.Provider>
  );
}

export function useBroadcastContext(topic: MessageTopic): IBroadcastContext {
  const [state, setState] = useState(Math.random().toString());
  const { post, subscribe, unsubscribe, setup } = useContext(BroadcastContext);
  useEffect(() => {
    setup();
    return () => {
      unsubscribe(state, topic);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return {
    send: (message?: string) => {
      post({ data: message || '', topic });
    },
    subscribe: (callback: (message: ICommunicationMessage) => void) => {
      subscribe(state, callback, topic);
    },
  };
}
